import React from 'react';
import { imageUrl } from 'theme';
import { useSelector } from 'react-redux';
import Dialog from 'seedly-component-library/dialog';
import Button from 'seedly-component-library/button-new';
import { RootState } from 'app/reducer';

const EmailVerifiedModal = props => {
  const {
    isEmailVerifiedModalOpen = false,
    toggleEmailVerifiedModal = () => {},
  } = props;

  const userEmail = useSelector((state: RootState) => state.profile.user.email);

  if (!userEmail) return null;

  const asteriskedEmail =
    userEmail[0] +
    '*'.repeat(userEmail.indexOf('@') - 1) +
    userEmail.slice(userEmail.indexOf('@'));

  return (
    <Dialog
      isOpen={isEmailVerifiedModalOpen}
      onCloseClick={() => toggleEmailVerifiedModal(false)}
    >
      <div className="flex flex-col w-full gap-8 items-center">
        <div className="flex flex-col w-full gap-3 items-center">
          <img
            src={imageUrl.success}
            height="84"
            width="84"
            alt="announcement"
          />
          <p className="text-xl font-bold">Verification email sent</p>
          <p className="text-neutral-500 text-center">
            We have resent a verification link to {asteriskedEmail}. Please
            verify your email within the next 3 days.
          </p>
        </div>
        <Button onClick={() => toggleEmailVerifiedModal(false)}>Close</Button>
      </div>
    </Dialog>
  );
};

export default EmailVerifiedModal;
